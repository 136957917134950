<template>
  <div ref="scrollContainer" class="scroll-container"  @scroll="checkScroll" >
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i>
            </router-link>
          </li>
          <li>
             {{$t('report.udemy.report')}}
          </li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left"><span> {{$t('report.udemy.report')}}</span></h2>
      </div>
    </div>

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0" />

      <template >
        <div v-if="items.length" class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{$t('excel.heading.full_name')}}</th>
                <th scope="col">{{$t('report.linkedin.mail')}}</th>
                <th scope="col">{{$t('report.linkedin.content')}}</th>
                <th scope="col">{{$t('report.linkedin.completionStatus')}}</th>
                <th scope="col">{{$t('general.start_date')}} </th>
                <th scope="col">{{$t('excel.heading.completion_date')}}</th>
                <th scope="col"> {{$t('general.completion_percentage')}}</th>
              </tr>
            </thead>
            <tbody class="list">
              <template v-for="item in items">
                <tr>
                  <td>{{ item.learner_name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.content_title }}</td>
                  <td>{{ item.is_completed ? $t('general.yes') : $t('general.no') }}</td>
                  <td>{{ item.start_date }}</td>
                  <td>{{ item.completion_date }}</td>
                  <td>{{ item.completion_percentage }}%</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div v-if="!isFetching && items.length" class="text-center mb-4">
          <button class="btn btn-primary" @click="getContent">
            {{$t('general.load_more')}}
          </button>
        </div>

        <div v-if="user_loading" class="uk-text-center uk-margin">
          <div class="loading-container">
            <div class="loading-text"> {{$t('general.loading')}}</div>
            <div uk-spinner class="loading-spinner"></div>
          </div>
        </div>

      </template>
    </div>
  </div>
</template>

<script>
import reports, { ERROR, GET_ITEMS, LOADING, MODULE_NAME, SUCCESS } from "@/core/services/store/reports.module";
import store from '@/core/services'
import DefaultLoading from "@/view/components/loading/DefaultLoading";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "UdemyReports",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, reports)
  },
  components: {  DefaultLoading },
  data() {
    return {
      items: [],
      error: null,
      page: 1,
      isFetching: false,
    }
  },
  computed: {
    user_loading() {
      return store.getters[_MODULE_NAME + '/' + LOADING];
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS]
    }
  },
  methods: {
    async getContent() {
      if (this.isFetching) return;
      this.isFetching = true;

      let udemyData=[]

      let filters = {
        page: this.page,
      };

      try {
        const response = await store.dispatch(_MODULE_NAME + '/' + GET_ITEMS, {
          url: 'api/report/udemy',
          filters: filters
        });

        udemyData=response.data.data

        if (response.status) {
          console.log("response.status",response)
          this.items = [...this.items, ...udemyData];
          this.page++;
        }
      } finally {
        udemyData.length? this.isFetching=false:this.isFetching = true;
      }
    },
    setPage(page) {
      this.page = page;
    },
    checkScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const bottomOfWindow = scrollContainer.getBoundingClientRect().bottom <= window.innerHeight + 50;
      if (bottomOfWindow) {
        this.getContent();
      }
    },
  },
  mounted() {
    this.getContent();
  },

};
</script>

